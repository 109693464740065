import * as React from 'react';
import { Button } from '@mui/material';
import { useFilterList } from '../../../context/FilterListProvider';

const ApproveButton = ({ callback }) => {
    const [filterList] = useFilterList();
    var isSkipIncluded = filterList.Tags_contains?.some(tag => tag.toLowerCase() == 'skip');
    return (
        <>
            {!isSkipIncluded && <Button variant='contained' color='success' onClick={callback} sx={{color: "white"}}>approve</Button>}
        </>

    )
}

export default ApproveButton;